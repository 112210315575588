<template>
  <renewPackage
    :PackageObj="PackageObj"
    @hide="ShowRenew = !ShowRenew"
    v-if="ShowRenew"
  />
  <upgradePackage
    :PackageObj="PackageObj"
    @hide="ShowUpgrade = !ShowUpgrade"
    v-if="ShowUpgrade"
  />
  <div class="required-item consulting">
    <div class="container">
      <div class="invoices">
        <div class="head-section-profile mb-5">
          <div class="select-wrapper">
                <h2 class="title-profile mt-0">{{ $route.name }}</h2>
                <p class="desc">{{ $t("subscriptions_page_desc") }}</p>
          </div>

          <div class="d-flex align-items-center">
            <div>
              <ul
                class="nav nav-pills nav-pills-order-details justify-content-end"
                id="pills-tab-invoices"
                role="tablist"
              >
                <li class="nav-item" role="presentation"  v-if="!mobile" >
                  <button
                    data-index="row-view"
                    class="nav-link"
                    id="v-pills-row-view-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-row-view"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-P-D-1"
                    aria-selected="true"
                  >
                    <span class="fs-sm-4">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 1.90909C0 0.854729 0.854729 0 1.90909 0H12.0909C13.1453 0 14 0.854729 14 1.90909V1.90909C14 2.96345 13.1453 3.81818 12.0909 3.81818H1.90909C0.854729 3.81818 0 2.96345 0 1.90909V1.90909Z"
                          fill="#052C57"
                        />
                        <path
                          d="M0 7C0 5.94564 0.854729 5.09091 1.90909 5.09091H12.0909C13.1453 5.09091 14 5.94564 14 7V7C14 8.05436 13.1453 8.90909 12.0909 8.90909H1.90909C0.854729 8.90909 0 8.05436 0 7V7Z"
                          fill="#052C57"
                        />
                        <path
                          d="M0 12.0909C0 11.0365 0.854729 10.1818 1.90909 10.1818H12.0909C13.1453 10.1818 14 11.0365 14 12.0909V12.0909C14 13.1453 13.1453 14 12.0909 14H1.90909C0.854729 14 0 13.1453 0 12.0909V12.0909Z"
                          fill="#052C57"
                        />
                      </svg>
                    </span>
                  </button>
                </li>
                <li class="nav-item mx-2" role="presentation"  v-if="!mobile">
                  <button
                    data-index="card-view"
                    class="nav-link"
                    id="v-pills-card-view-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#v-pills-card-view"
                    type="button"
                    role="tab"
                    aria-controls="v-pills-card-view"
                    aria-selected="true"
                  >
                    <span class="fs-sm-4">
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0 0.999999C0 0.447714 0.447715 0 1 0H5.58824C6.14052 0 6.58824 0.447715 6.58824 1V5.58824C6.58824 6.14052 6.14052 6.58824 5.58824 6.58824H0.999999C0.447714 6.58824 0 6.14052 0 5.58824V0.999999Z"
                          fill="#F2F6FB"
                        />
                        <path
                          d="M7.41176 0.999999C7.41176 0.447714 7.85948 0 8.41176 0H13C13.5523 0 14 0.447715 14 1V5.58824C14 6.14052 13.5523 6.58824 13 6.58824H8.41176C7.85948 6.58824 7.41176 6.14052 7.41176 5.58824V0.999999Z"
                          fill="#F2F6FB"
                        />
                        <path
                          d="M7.41176 8.41176C7.41176 7.85948 7.85948 7.41176 8.41176 7.41176H13C13.5523 7.41176 14 7.85948 14 8.41176V13C14 13.5523 13.5523 14 13 14H8.41176C7.85948 14 7.41176 13.5523 7.41176 13V8.41176Z"
                          fill="#F2F6FB"
                        />
                        <path
                          d="M0 8.41176C0 7.85948 0.447715 7.41176 1 7.41176H5.58824C6.14052 7.41176 6.58824 7.85948 6.58824 8.41176V13C6.58824 13.5523 6.14052 14 5.58824 14H0.999999C0.447714 14 0 13.5523 0 13V8.41176Z"
                          fill="#F2F6FB"
                        />
                      </svg>
                    </span>
                  </button>
                </li>
              </ul>
            </div>
            <div>
              <router-link to="/packages" class="btn-main">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.73684 14.7368C6.73684 15.0719 6.86992 15.3931 7.10681 15.63C7.3437 15.8669 7.66499 16 8 16C8.33501 16 8.6563 15.8669 8.89319 15.63C9.13008 15.3931 9.26316 15.0719 9.26316 14.7368V9.26316H14.7368C15.0719 9.26316 15.3931 9.13008 15.63 8.89319C15.8669 8.6563 16 8.33501 16 8C16 7.66499 15.8669 7.3437 15.63 7.10681C15.3931 6.86992 15.0719 6.73684 14.7368 6.73684H9.26316V1.26316C9.26316 0.928148 9.13008 0.606858 8.89319 0.36997C8.6563 0.133082 8.33501 0 8 0C7.66499 0 7.3437 0.133082 7.10681 0.36997C6.86992 0.606858 6.73684 0.928148 6.73684 1.26316V6.73684H1.26316C0.928148 6.73684 0.606858 6.86992 0.36997 7.10681C0.133082 7.3437 0 7.66499 0 8C0 8.33501 0.133082 8.6563 0.36997 8.89319C0.606858 9.13008 0.928148 9.26316 1.26316 9.26316H6.73684V14.7368Z"
                    fill="white"
                  />
                </svg>
                {{ $t("Request_a_package") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="row mb-4 align-items-end" >
            <div class="col-md-11 " >
                <div class="row align-items-end" >
                    <div class="col-md-3 mb-3 mb-md-0 select-wrapper">
                        <label class="label" for="">{{ $t("Release_date") }}</label>
                        <div class="position-relative">
                            <input
                            v-model="Release_date"
                            class="bg-white"
                            type="date"
                            :placeholder="$t('Release_date')"
                            />
                            <span
                            v-if="Release_date"
                            class="clear-icon"
                            style="right: 5px;"
                            @click="Release_date = '';End_date = '';Filter()"
                            >×</span
                            >
                        </div>
                    </div>
                    <div class="col-md-3 mb-3 mb-md-0 select-wrapper">
                        <label class="label" for="">{{ $t("End_date") }}</label>
                        <div class="position-relative">
                            <input
                            v-model="End_date"
                            class="bg-white"
                            type="date"
                            :placeholder="$t('End_date')"
                            />
                            <span
                            v-if="End_date"
                            class="clear-icon"
                            style="right: 5px;"
                            @click="Release_date = '';End_date = '';Filter()"
                            >×</span
                            >
                        </div>
                    </div>
                    <div class="col-md-3 mb-3 mb-md-0 select-wrapper">
                        <select
                        class="bg-white"
                        v-model="selectedStatus"
                        >
                        <option value="" disabled hidden>
                            {{
                            this.$i18n.locale == "ar"
                                ? "التصنيف حسب الحالة.."
                                : "Filter By Status..."
                            }}
                        </option>
                        <option value="active">{{ $t("active") }}</option>
                        <option value="terminated">{{ $t("terminated") }}</option>
                        </select>
                        <span
                        v-if="selectedStatus"
                        class="clear-icon"
                        @click="selectedStatus = '';Filter()"
                        >×</span
                        >
                    </div>
                    <div class="col-md-3 mb-3 mb-md-0 select-wrapper">
                        <select
                        v-model="selectedPlan"
                        class="bg-white"
                        >
                        <option value="" disabled hidden>
                            {{
                            this.$i18n.locale == "ar"
                                ? "التصنيف حسب الخطة.."
                                : "Filter By Plan..."
                            }}
                        </option>
                        <option value="annual">{{ $t("annual") }}</option>
                        <option value="semi-annual">{{ $t("semi-annual") }}</option>
                        <!-- <option value="quarterly">{{ $t('quarterly') }}</option>
                                        <option value="monthly">{{ $t('monthly') }}</option> -->
                        </select>
                        <span v-if="selectedPlan" class="clear-icon" @click="selectedPlan = '';Filter()"
                        >×</span
                        >
                    </div>
                </div>
            </div>
            <div class="col-md-1 p-0 text-center text-md-end">
                <button @click="Filter" class="btn-main">
                {{ $t("search") }}
                </button>
            </div>
        </div>
        <div class="row mb-3 align-items-end" >
        </div>

        <div class="tab-content p-d mt-3" id="pills-tabContent">
          <div
            class="tab-pane fade show row-view"
              :class="[!mobile ? 'active' : '']"
            id="v-pills-row-view"
            role="tabpanel"
            aria-labelledby="v-pills-row-view-tab"
          >
            <div class="consulting-tabel" v-if="Subscriptions.length > 0">
              <div class="row head-tabel">
                <div class="col">
                  {{ $t("subscription_id") }}
                </div>
                <div class="col">
                  {{ $t("Name") }}
                </div>
                <div class="col">
                  {{ $t("Number of commercial records") }}
                </div>
                <div class="col">
                  {{ $t("Start_date") }}
                </div>
                <div class="col">
                  {{ $t("End_date") }}
                </div>
                <div class="col">
                  {{ $t("Payment_cycle") }}
                </div>
                <div class="col">
                  {{ $t("subscription_price") }}
                </div>
                <div class="col">
                  {{ $t("Status") }}
                </div>
                <div class="col">
                  {{ $t("Management") }}
                </div>
              </div>
              <div
                class="row body-tabel"
                v-if="Subscriptions.length > 0"
                v-for="(item, index) in Subscriptions"
                :key="index"
              >
                <div class="col">
                  {{ item.id }}
                </div>
                <div class="col">
                  {{ item.packageName }}
                </div>
                <div class="col">
                  {{ item.number_of_commercial_registers }}
                </div>
                <div class="col">
                  {{ item.start_date }}
                </div>
                <div class="col">
                  {{ item.end_date }}
                </div>
                <div class="col text-center">
                  {{ $t(item.plan) }}
                </div>
                <div class="col text-center">
                  {{ item.amount }}
                </div>
                <div
                  class="col text-center"
                  :class="[(item.status == 'active' || item.status =='مفعل') ? 'active' : 'not-active']"
                >
                  {{ $t(item.status) }}
                </div>
                <div class="col position-relative  align-items-center">
                  <li class="nav-item dropdown">
                    <router-link
                      :to="`/packages/order/details/${item.package_request_id}`"
                    >
                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="SVGRepo_bgCarrier" stroke-width="0"/>
                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
                        <g id="SVGRepo_iconCarrier"> <path d="M15.0007 12C15.0007 13.6569 13.6576 15 12.0007 15C10.3439 15 9.00073 13.6569 9.00073 12C9.00073 10.3431 10.3439 9 12.0007 9C13.6576 9 15.0007 10.3431 15.0007 12Z" stroke="#0349A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#0349A8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> </g>
                        </svg>
                  </router-link>
                  </li>
<!--                  <li class="nav-item dropdown">-->
<!--                    <a-->
<!--                      class="nav-link dropdown-toggle"-->
<!--                      href="#"-->
<!--                      id="navbarDropdown"-->
<!--                      role="button"-->
<!--                      data-bs-toggle="dropdown"-->
<!--                      aria-expanded="false"-->
<!--                    >-->
<!--                      <svg-->
<!--                        style="cursor: pointer; width: 40px"-->
<!--                        width="4"-->
<!--                        height="14"-->
<!--                        viewBox="0 0 4 14"-->
<!--                        fill="none"-->
<!--                        xmlns="http://www.w3.org/2000/svg"-->
<!--                      >-->
<!--                        <path-->
<!--                          d="M2 3.40625C2.82843 3.40625 3.5 2.73468 3.5 1.90625C3.5 1.07782 2.82843 0.40625 2 0.40625C1.17157 0.40625 0.5 1.07782 0.5 1.90625C0.5 2.73468 1.17157 3.40625 2 3.40625Z"-->
<!--                          fill="#0349A8"-->
<!--                        />-->
<!--                        <path-->
<!--                          d="M2 8.51562C2.82843 8.51562 3.5 7.84405 3.5 7.01562C3.5 6.1872 2.82843 5.51562 2 5.51562C1.17157 5.51562 0.5 6.1872 0.5 7.01562C0.5 7.84405 1.17157 8.51562 2 8.51562Z"-->
<!--                          fill="#0349A8"-->
<!--                        />-->
<!--                        <path-->
<!--                          d="M2 13.5937C2.82843 13.5937 3.5 12.9222 3.5 12.0937C3.5 11.2653 2.82843 10.5938 2 10.5938C1.17157 10.5938 0.5 11.2653 0.5 12.0937C0.5 12.9222 1.17157 13.5937 2 13.5937Z"-->
<!--                          fill="#0349A8"-->
<!--                        />-->
<!--                      </svg>-->
<!--                    </a>-->
<!--                    <ul-->
<!--                      class="dropdown-menu setting"-->
<!--                      aria-labelledby="navbarDropdown"-->
<!--                    >-->
<!--                      <li @click="OpenRenew(item)">-->
<!--                        <span> {{ $t("Package_renewal") }}</span>-->
<!--                      </li>-->
<!--                      <li @click="OpenUpgradePackage(item)">-->
<!--                        <span> {{ $t("Package_upgrade") }}</span>-->
<!--                      </li>-->
<!--                    </ul>-->
<!--                  </li>-->
                </div>
              </div>
              <NoDataToShow v-else />
            </div>
            <NoDataToShow v-else />
          </div>
          <div
            id="v-pills-card-view"

              class="tab-pane fade show card-view"
              :class="[mobile ? 'active' : '']"
            role="tabpanel"
            aria-labelledby="v-pills-card-view-tab"
          >
            <div class="row" v-if="Subscriptions.length > 0">
              <div
                class="col-md-4"
                v-for="(item, index) in Subscriptions"
                :key="index"
              >
                <div
                  class="card-pack-req silver"
                  :class="[
                    item.packageName == 'Silver Package' ||
                    item.packageName == 'الباقة الفضية'
                      ? 'silver'
                      : '',
                    item.packageName == 'Gold Package' ||
                    item.packageName == 'الباقة الذهبية'
                      ? 'gold'
                      : '',
                    item.packageName == 'Platinum Package' ||
                    item.packageName == 'الباقة البلاتينية'
                      ? 'platinum'
                      : '',
                  ]"
                >
<!--                  <div-->
<!--                    class="d-flex justify-content-between align-items-center"-->
<!--                  >-->
<!--                    <h2 class="name">-->
<!--                      {{ item.packageName }}-->
<!--                    </h2>-->
<!--                    <li class="nav-item dropdown">-->
<!--                      <a-->
<!--                        class="nav-link dropdown-toggle"-->
<!--                        href="#"-->
<!--                        id="navbarDropdown"-->
<!--                        role="button"-->
<!--                        data-bs-toggle="dropdown"-->
<!--                        aria-expanded="false"-->
<!--                      >-->
<!--                        <svg-->
<!--                          style="cursor: pointer; margin-top: -10px"-->
<!--                          width="18"-->
<!--                          height="5"-->
<!--                          viewBox="0 0 18 5"-->
<!--                          fill="none"-->
<!--                          xmlns="http://www.w3.org/2000/svg"-->
<!--                        >-->
<!--                          <path-->
<!--                            d="M14 2.5C14 3.60457 14.8954 4.5 16 4.5C17.1046 4.5 18 3.60457 18 2.5C18 1.39543 17.1046 0.5 16 0.5C14.8954 0.5 14 1.39543 14 2.5Z"-->
<!--                            fill="#052C57"-->
<!--                          />-->
<!--                          <path-->
<!--                            d="M7 2.5C7 3.60457 7.89543 4.5 9 4.5C10.1046 4.5 11 3.60457 11 2.5C11 1.39543 10.1046 0.5 9 0.5C7.89543 0.5 7 1.39543 7 2.5Z"-->
<!--                            fill="#052C57"-->
<!--                          />-->
<!--                          <path-->
<!--                            d="M8.66252e-07 2.5C8.17969e-07 3.60457 0.895431 4.5 2 4.5C3.10457 4.5 4 3.60457 4 2.5C4 1.39543 3.10457 0.5 2 0.5C0.895431 0.5 9.14534e-07 1.39543 8.66252e-07 2.5Z"-->
<!--                            fill="#052C57"-->
<!--                          />-->
<!--                        </svg>-->
<!--                      </a>-->
<!--                      <ul-->
<!--                        class="dropdown-menu setting"-->
<!--                        aria-labelledby="navbarDropdown"-->
<!--                      >-->
<!--                        <li @click="OpenRenew(item)">-->
<!--                          <span> {{ $t("Package_renewal") }}</span>-->
<!--                        </li>-->
<!--                        <li @click="OpenUpgradePackage(item)">-->
<!--                          <span> {{ $t("Package_upgrade") }}</span>-->
<!--                        </li>-->
<!--                      </ul>-->
<!--                    </li>-->
<!--                  </div>-->

                  <div class="data-list">
                    <div class="item">
                      <div class="key">{{ $t("Name") }}</div>
                      <div class="value">{{ item.packageName }}</div>
                    </div>
                    <div class="item">
                      <div class="key">
                        {{ $t("Number of commercial records") }}
                      </div>
                      <div class="value">
                        {{ item.number_of_commercial_registers }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t("Release_date") }}</div>
                      <div class="value">{{ item.start_date }}</div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t("End_date") }}</div>
                      <div class="value" style="color: #ce4747">
                        {{ item.end_date }}
                      </div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t("Payment_cycle") }}</div>
                      <div class="value">{{ $t(item.plan) }}</div>
                    </div>
                    <div class="item">
                      <div class="key">{{ $t("monthly_installment") }}</div>
                      <div class="value">{{ item.amount }}</div>
                    </div>
                  </div>
                  <div class="bottom-card">
                    <div class="status review">{{ $t(item.status) }}</div>
                    <router-link
                      :to="`/packages/order/details/${item.package_request_id}`"
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.31779 11.6027L0.373973 6.53275C0.299066 6.45593 0.245883 6.37271 0.214422 6.28309C0.182962 6.19347 0.167481 6.09745 0.16798 5.99502C0.16798 5.8926 0.183711 5.79658 0.215172 5.70696C0.246632 5.61733 0.299566 5.53412 0.373973 5.4573L5.31779 0.387326C5.45512 0.246494 5.6269 0.173005 5.83314 0.16686C6.03938 0.160714 6.21716 0.234203 6.36647 0.387326C6.51629 0.528159 6.59444 0.704328 6.60093 0.915833C6.60742 1.12734 6.53551 1.30965 6.3852 1.46277L2.71479 5.22684H11.0856C11.2978 5.22684 11.4758 5.30059 11.6197 5.44808C11.7635 5.59557 11.8351 5.77788 11.8346 5.99502C11.8346 6.21267 11.763 6.39524 11.6197 6.54273C11.4763 6.69022 11.2983 6.76371 11.0856 6.7632H2.71479L6.3852 10.5273C6.52253 10.6681 6.59444 10.8473 6.60093 11.065C6.60742 11.2826 6.53551 11.4619 6.3852 11.6027C6.24787 11.7564 6.07309 11.8332 5.86086 11.8332C5.64862 11.8332 5.4676 11.7564 5.31779 11.6027Z"
                          fill="#0349A8"
                        />
                      </svg>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <NoDataToShow v-else />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";
import axios from "axios";
import { notify } from "@kyvg/vue3-notification";
import Cookie from "cookie-universal";
const cookie = Cookie();
export default {
  data() {
    return {
      // index: 1,
      // pageLength: 5,
      PackageObj: {},
      ShowRenew: false,
      ShowUpgrade: false,
      mobile: window.innerWidth < 768,
      Subscriptions: [],
      Filter_Type: "",
      Release_date: "",
      End_date: "",
      selectedStatus: "",
      selectedPlan: "",
    };
  },
  components: {
    renewPackage: defineAsyncComponent(() =>
      import("@/components/Profile/modals/RenewPackage.vue")
    ),
    upgradePackage: defineAsyncComponent(() =>
      import("@/components/Profile/modals/upgradePackage.vue")
    ),
    NoDataToShow: defineAsyncComponent(() =>
      import("@/components/Global/NoDataToShow.vue")
    ),
  },
  methods: {
    clearFilter() {
      this.Filter_Type = ""; // Clear the selection
      this.GetSubscriptions();
    },
    OpenRenew(item) {
      this.PackageObj = item;
      this.ShowRenew = !this.ShowRenew;
    },
    OpenUpgradePackage(item) {
      this.PackageObj = item;
      this.ShowUpgrade = !this.ShowUpgrade;
    },
    clearDate(field) {
      if (field === "Release_date") {
        this.Release_date = "";
      } else if (field === "End_date") {
        this.End_date = "";
      }
    },
    clearStatus() {
      this.selectedStatus = "";
      this.GetSubscriptions();
    },
    clearPlan() {
      this.GetSubscriptions();
    },
    // prevPage() {
    //     if (this.index != 1) {
    //         this.index--
    //     }
    // },
    // nextPage() {
    //     if (this.index != this.pageLength) {
    //         this.index++
    //     }
    // },
    GetSubscriptions() {
      this.$store.commit("SET_Loader", true);
      axios
        .get(`/company/dashboard/subscriptions`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Subscriptions = res.data.data.Subscription;
          this.$store.commit("SET_Loader", false);
        })
        .catch(function (error) {
          this.$store.commit("SET_Loader", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    Filter(){
        let queryParams = '?' ;
        queryParams += this.selectedPlan != '' && this.selectedPlan != undefined ? `plan=${this.selectedPlan}&` : ``;
        queryParams += this.selectedStatus != '' && this.selectedPlan != undefined ? `status=${this.selectedStatus}&` : ``;

        queryParams +=  ( this.Release_date != '' && this.Release_date != undefined ) &&
                        ( this.End_date != '' && this.End_date != undefined )
                        ? `start_date=${this.Release_date}&end_date=${this.End_date}&` : ``;

        queryParams =   queryParams.slice(0, -1);

        this.$store.commit("SET_Loader", true);
        axios
          .get(
            `/company/dashboard/subscriptions${queryParams}`,
            {
              headers: {
                Authorization: `Bearer ${cookie.get("CompanyToken")}`,
              },
            }
          )
          .then((res) => {
            this.Subscriptions = res.data.data.Subscription;
            this.$store.commit("SET_Loader", false);
          })
          .catch(function (error) {
            this.$store.commit("SET_Loader", false);
            notify({
              type: "error",
              text: error.response.data.message,
            });
          });
    },
    FilterByDate() {
      if (this.Release_date != "" && this.End_date != "") {
        this.$store.commit("SET_Loader", true);
        axios
          .get(
            `/company/dashboard/subscriptions?start_date=${this.Release_date}&end_date=${this.End_date}`,
            {
              headers: {
                Authorization: `Bearer ${cookie.get("CompanyToken")}`,
              },
            }
          )
          .then((res) => {
            this.Subscriptions = res.data.data.Subscription;
            this.$store.commit("SET_Loader", false);
          })
          .catch(function (error) {
            this.$store.commit("SET_Loader", false);
            notify({
              type: "error",
              text: error.response.data.message,
            });
          });
      }
    },
    FilterByStatus(e) {
      this.$store.commit("SET_Loader", true);
      axios
        .get(`/company/dashboard/subscriptions?status=${event.target.value}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Subscriptions = res.data.data.Subscription;
          this.$store.commit("SET_Loader", false);
        })
        .catch(function (error) {
          this.$store.commit("SET_Loader", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    FilterByPlan(e) {
      this.$store.commit("SET_Loader", true);
      axios
        .get(`/company/dashboard/subscriptions?plan=${event.target.value}`, {
          headers: {
            Authorization: `Bearer ${cookie.get("CompanyToken")}`,
          },
        })
        .then((res) => {
          this.Subscriptions = res.data.data.Subscription;
          this.$store.commit("SET_Loader", false);
        })
        .catch(function (error) {
          this.$store.commit("SET_Loader", false);
          notify({
            type: "error",
            text: error.response.data.message,
          });
        });
    },
    checkMobile() {
      this.mobile = window.innerWidth < 768;
    }
  },

  mounted() {
    this.GetSubscriptions();
    window.addEventListener("resize", this.checkMobile);
  },
};
</script>
<style scoped>
.select-wrapper {
  position: relative; /* Position relative for the clear icon */
  display: inline-block; /* Adjust as needed */
}

.select-wrapper select {
  padding-right: 30px; /* Space for the clear icon */
}

.clear-icon {
  position: absolute;
  right: 17px; /* Adjust as needed */
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em; /* Adjust size */
  color: #888; /* Color for the clear icon */
}

.clear-icon-main {
  position: absolute;
  right: 17px; /* Adjust as needed */
  top: 42%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em; /* Adjust size */
  color: #888; /* Color for the clear icon */
}
.clear-icon:hover {
  color: #f00; /* Change color on hover */
}
</style>
